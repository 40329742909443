import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import MessageSent from 'svg/message-sent.svg';
import Button from 'components/Button';
import useForm, {
  STATUS_ERROR,
  STATUS_SENDING,
  STATUS_SENT,
} from 'hooks/useForm';
import './Form.module.css';

const sendRequest = (endpoint) => (body) => (
  new Promise((success, failure) => {
    const xhr = new XMLHttpRequest();

    xhr.open('POST', endpoint, true);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.send(JSON.stringify(body));

    xhr.onloadend = (response) => {
      if (response.target.status >= 200 && response.target.status < 300) {
        success();
      } else {
        failure();
      }
    };
  })
);

function Sent({ intl, resetForm }) {
  return (
    <div styleName="sent">
      <h5>
        {intl.formatMessage({ id: 'contact.send-success' })}
      </h5>

      <MessageSent />

      <Button data-fancy onClick={resetForm}>
        {intl.formatMessage({ id: 'contact.send-another' })}
      </Button>
    </div>
  );
}

Sent.propTypes = {
  intl: intlShape.isRequired,
  resetForm: PropTypes.func.isRequired,
};

const Form = ({ intl, submissionEndpoint, ...props }) => {
  const nameInput = useRef(null);
  const [
    handleChange,
    handleSubmit,
    {
      name = '',
      email = '',
      title = '',
      company = '',
      message = '',
      role = '',
      contactReason = '',
    },
    status,
  ] = useForm(sendRequest(submissionEndpoint), { locale: intl.locale });
  const [requestCompleted, setRequestCompleted] = useState(false);

  function submitForm(event) {
    handleSubmit(event).then(() => {
      setRequestCompleted(true);
    });
  }

  // focus the name input when user navigates to /contact/#demo-form
  useEffect(() => {
    if (window.location.hash === '#demo-form') {
      nameInput.current.focus();
    }
  }, []);

  if (requestCompleted && status === STATUS_SENT) {
    return (
      <div styleName="form">
        <Sent intl={intl} resetForm={() => setRequestCompleted(false)} />
      </div>
    );
  }

  return (
    <form {...props} styleName="form" onSubmit={submitForm}>
      <div data-width="50">
        <label htmlFor="name">
          <input
            type="text"
            id="name"
            name="name"
            placeholder={`${intl.formatMessage({ id: 'contact.form.name' })}*`}
            onChange={handleChange}
            value={name}
            ref={nameInput}
            required
          />
        </label>
      </div>

      <div data-width="50">
        <label htmlFor="email">
          <input
            type="email"
            id="email"
            name="email"
            placeholder={`${intl.formatMessage({ id: 'contact.form.email' })}*`}
            onChange={handleChange}
            value={email}
            required
          />
        </label>
      </div>

      <div styleName="role-select">
        <span>
          {`${intl.formatMessage({ id: 'contact.form.role-label' })}:`}
        </span>

        <div>
          <label htmlFor="form-role-1">
            <input
              type="radio"
              name="role"
              id="form-role-1"
              value="system_integrator"
              checked={role === 'system_integrator'}
              onChange={handleChange}
              required
            />

            {intl.formatMessage({ id: 'contact.form.integrator' })}
          </label>
        </div>

        <div>
          <label htmlFor="form-role-2">
            <input
              type="radio"
              name="role"
              id="form-role-2"
              value="developer"
              checked={role === 'developer'}
              onChange={handleChange}
            />

            {intl.formatMessage({ id: 'contact.form.developer' })}
          </label>
        </div>

        <div>
          <label htmlFor="form-role-3">
            <input
              type="radio"
              name="role"
              id="form-role-3"
              value="oem_vendor"
              checked={role === 'oem_vendor'}
              onChange={handleChange}
            />

            {intl.formatMessage({ id: 'contact.form.oem_vender' })}
          </label>
        </div>

        <div>
          <label htmlFor="form-role-4">
            <input
              type="radio"
              name="role"
              id="form-role-4"
              value="other"
              checked={role === 'other'}
              onChange={handleChange}
            />

            {intl.formatMessage({ id: 'contact.form.other' })}
          </label>

        </div>
      </div>

      <div data-width="50">
        <label htmlFor="company">
          <input
            type="text"
            id="company"
            name="company"
            placeholder={`${intl.formatMessage({ id: 'contact.form.company' })}*`}
            onChange={handleChange}
            value={company}
            required
          />
        </label>
      </div>

      <div data-width="50">
        <label htmlFor="title">
          <input
            type="text"
            id="title"
            name="title"
            placeholder={
              `${intl.formatMessage({ id: 'contact.form.title' })}${role === 'other' ? '*' : ''}`
            }
            onChange={handleChange}
            value={title}
            required={role === 'other'}
          />
        </label>
      </div>

      <div styleName="select">
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label htmlFor="contact-reason">
          <select
            name="contactReason"
            id="contact-reason"
            onChange={handleChange}
            value={contactReason}
            data-is-placeholder={contactReason === ''}
            required
          >
            <option value="" disabled>
              {`${intl.formatMessage({ id: 'contact.form.reason-label' })}*`}
            </option>

            <option value="book_demo">
              {intl.formatMessage({ id: 'contact.form.reason.book-demo' })}
            </option>

            <option value="individual_installation">
              {intl.formatMessage({ id: 'contact.form.reason.individual-installation' })}
            </option>

            <option value="large_scale">
              {intl.formatMessage({ id: 'contact.form.reason.large-scale' })}
            </option>

            <option value="incorporate_brainframe">
              {intl.formatMessage({ id: 'contact.form.reason.incorporate-brainframe' })}
            </option>

            <option value="other">
              {intl.formatMessage({ id: 'contact.form.other' })}
            </option>
          </select>
        </label>
      </div>

      <div>
        <label htmlFor="message">
          <textarea
            id="message"
            name="message"
            rows="7"
            placeholder={intl.formatMessage({ id: 'contact.form.message' })}
            onChange={handleChange}
            value={message}
          />
        </label>
      </div>

      <div>
        <Button
          type="submit"
          data-fancy
          disabled={status === STATUS_SENDING}
          aria-label={intl.formatMessage({ id: 'contact.form.send' })}
        >
          {status === STATUS_SENDING ? (
            intl.formatMessage({ id: 'contact.form.sending' })
          ) : (
            intl.formatMessage({ id: 'contact.form.send' })
          )}
        </Button>
      </div>

      {status === STATUS_ERROR && (
        <div styleName="status-message">
          {intl.formatMessage({ id: 'contact.send-failure' })}
          {' '}
          <a href="mailto:sales@aotu.ai">
            sales@aotu.ai
          </a>
          .
        </div>
      )}
    </form>
  );
};

Form.propTypes = {
  submissionEndpoint: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(Form);
