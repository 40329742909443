import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from 'components/Layout';
import ContactPage from 'components/pages/ContactPage';
import useAotuAPI from '../hooks/useAotuAPI';

const Contact = () => {
  const aotuAPI = useAotuAPI();
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          forumURL
        }
      }
    }
  `);

  const { forumURL } = data.site.siteMetadata;
  const contactFormEndpoint = aotuAPI.getContactFormSubmissionEndpoint();

  return (
    <Layout>
      <Helmet title="Contact Us" />

      <ContactPage contactUsEndpoint={contactFormEndpoint} forumURL={forumURL} />
    </Layout>
  );
};

export default Contact;
