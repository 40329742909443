/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, injectIntl, intlShape } from 'gatsby-plugin-intl';

import Container from 'components/Container';
import EmailListSignup from 'components/EmailListSignup';
import Envelope from 'svg/envelope.svg';
import Chat from 'svg/comments-solid.svg';
import Marker from 'svg/marker.svg';
import Form from './Form';
import './ContactPage.module.css';

const CONTACT_TEXT = [{
  heading: 'contact.request-demo',
  body: 'contact.request-demo-blurb',
}, {
  heading: 'contact.ask-question',
  body: 'contact.ask-question-blurb',
}, {
  heading: 'contact.hi',
  link: '/download/',
  linkText: 'contact.hi-blurb',
}];

const ContactPage = ({
  intl,
  contactUsEndpoint,
  forumURL,
}) => (
  <Container>
    <header styleName="header">
      <h4>
        {intl.formatMessage({ id: 'contact.contact-us' })}
      </h4>

      <div styleName="contact-info">
        <div>
          <div styleName="icon">
            <Marker />
          </div>

          <span>
            {intl.formatMessage({ id: 'common.mailAddress' })}
          </span>
        </div>

        <div>
          <a
            href={`mailto:${intl.formatMessage({ id: 'common.emailAddress' })}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div styleName="icon">
              <Envelope />
            </div>

            {intl.formatMessage({ id: 'common.emailAddress' })}
          </a>
        </div>

        <div>
          <a
            href={forumURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div styleName="icon">
              <Chat />
            </div>

            <span style={{ textDecoration: 'underline' }}>
              {intl.formatMessage({ id: 'common.join-forum' })}
            </span>
          </a>
        </div>
      </div>
    </header>

    <section styleName="contact-section">
      <div styleName="mobile-text">
        <h5>
          {intl.formatMessage({ id: 'contact.request-demo' })}
        </h5>
        <p>
          {intl.formatMessage({ id: 'contact.request-demo-blurb' })}
        </p>
      </div>

      <Form id="demo-form" submissionEndpoint={contactUsEndpoint} />

      <aside>
        {CONTACT_TEXT.map(({
          heading,
          body,
          link,
          linkText,
        }) => (
          <div key={heading}>
            <h5>
              {intl.formatMessage({ id: heading })}
            </h5>

            {body && (
              <p>
                {intl.formatMessage({ id: body })}
              </p>
            )}

            {link && (
              <Link to={link}>
                {intl.formatMessage({ id: linkText })}
              </Link>
            )}
          </div>
        ))}
      </aside>
    </section>

    <EmailListSignup />
  </Container>
);

ContactPage.propTypes = {
  contactUsEndpoint: PropTypes.string.isRequired,
  forumURL: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ContactPage);
